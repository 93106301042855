import axios from "axios";
import { api_host } from "../request/host";


axios.defaults.baseURL = api_host;
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = localStorage.getItem("language") || "en-us";
    config.baseURL = axios.defaults.baseURL;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response) {
      if (response.status === 200) {
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    } else {
      return Promise.reject(new Error("No response received"));
    }
  },
  (error) => {}
);
export default {
  post(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        params: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
