<template>
  <div ref="preloader" class="preloader">
    <div class="preloader-inner">
      <div class="preloader-icon"></div>
    </div>
  </div>

  <header class="header navbar-area" ref="navbar-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="nav-inner">
            <!-- Start Navbar -->
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand">
                <img src="/assets/images/logo/logo.svg" alt="Logo" @click="gohome" />
              </a>
              <button
                class="navbar-toggler mobile-menu-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="nav" class="navbar-nav ms-auto">
                  <li class="nav-item" @click="gohome">
                    <a class="dd-menu collapsed">{{ $t("home") }}</a>
                  </li>

                  <li class="nav-item">
                    <a href="contact.html" aria-label="Toggle navigation">{{ $t("document") }}</a>
                  </li>
                  <li class="nav-item">
                    <a href="https://t.me/Moon20081314" aria-label="Toggle navigation">{{ $t("serve") }}</a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="dd-menu collapsed"
                      href="javascript:void(0)"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu-1-3"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      >{{ $t("language") }}</a
                    >
                    <ul class="sub-menu collapse" id="submenu-1-3">
                      <li class="nav-item"><a href="#" @click="switchToChinese">简体中文</a></li>
                      <li class="nav-item"><a href="#" @click="switchToEnglish">English</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="button">
                <a href="#" class="signin" @click="gologin">{{ $t("login") }}</a>
                <a href="#" class="signup" @click="goregister">{{ $t("register") }}</a>
              </div>
            </nav>
            <!-- End Navbar -->
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </header>

  <div class="account-login section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
          <form class="card login-form inner-content" @submit.prevent>
            <div class="card-body">
              <div class="title">
                <h3>商户登录</h3>
              </div>
              <div class="input-head">
                <div class="form-group input-group">
                  <label>
                    <img src="/assets/images/hero/user.png" alt="" style="width: 1.3rem; height: 1.3rem" />
                  </label>

                  <input
                    class="form-control"
                    type="text"
                    v-model="username"
                    id="reg-email"
                    placeholder="请输入用户名"
                    required
                  />
                </div>
                <div class="form-group input-group">
                  <label>
                    <img src="/assets/images/hero/password.png" alt="" style="width: 1.3rem; height: 1.3rem" />
                  </label>
                  <input
                    class="form-control"
                    type="password"
                    v-model="password"
                    id="reg-pass"
                    placeholder="请输入密码"
                    required
                  />
                </div>
              </div>

              <div class="button">
                <button class="btn" @click="uplogin">商户登录</button>
                <a class="btn alt" @click="goregister">注册账户</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <footer class="footer section">
    <!-- Start Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="inner-content">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <!-- Single Widget -->
              <div class="single-footer f-about">
                <div class="logo">
                  <a href="index.html">
                    <img src="/assets/images/logo/logo.svg" alt="#" />
                  </a>
                </div>
                <p>
                  专注于印度支付行业深耕，是印度支付领域荣誉品牌，我们将为您提供至安全至稳定的支付服务体验！
                  选择我们，是选择信心与便利并存的双赢体验！
                </p>
                <p class="copyright-text">
                  <span>© 2023 All Rights Reserved.</span>
                </p>
              </div>
              <!-- End Single Widget -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Footer Top -->
  </footer>
  <!--/ End Footer Area -->
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import WOW from "../../public/assets/js/wow.min.js";

export default {
  setup() {
    const preloader = ref(null);
    const router = useRouter();

    const username = ref("");

    const password = ref("");

    const gologin = () => {
      const searchUrl = "https://www.google.com/";

      window.location.href = searchUrl;
    };
    const switchToChinese = () => {
      localStorage.setItem("language", "zh-CN");

      window.location.reload();
    };

    const switchToEnglish = () => {
      localStorage.setItem("language", "en-US");

      window.location.reload();
    };

    const gohome = () => {
      router.push("/");
    };

    const goregister = () => {
      router.push("/register");
    };

    const uplogin = () => {
      console.log(username.value, password.value);
    };

    const fadeout = () => {
      if (preloader.value) {
        preloader.value.style.opacity = "0";
        preloader.value.style.display = "none";
      }
    };

    const handleScroll = () => {
      const navbarArea = document.querySelector(".navbar-area");
      if (!navbarArea) return; // 添加条件以确保 navbarArea 不为 null

      const sticky = navbarArea.offsetTop;

      if (window.pageYOffset > sticky) {
        navbarArea.classList.add("sticky");
      } else {
        navbarArea.classList.remove("sticky");
      }

      const backToTop = document.querySelector(".scroll-top");
      if (!backToTop) return; // 添加条件以确保 backToTop 不为 null
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        backToTop.style.display = "flex";
      } else {
        backToTop.style.display = "none";
      }
    };

    onMounted(() => {
      window.setTimeout(fadeout, 500);
      new WOW().init();
      window.addEventListener("scroll", handleScroll);
    });

    return {
      preloader,
      gologin,
      username,
      password,
      uplogin,
      gohome,
      goregister,
      switchToChinese,
      switchToEnglish,
    };
  },
};
</script>

<style lang="scss" scoped></style>
