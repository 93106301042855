<template>
  <div ref="preloader" class="preloader">
    <div class="preloader-inner">
      <div class="preloader-icon"></div>
    </div>
  </div>

  <header class="header navbar-area" ref="navbar-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="nav-inner">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" @click="gohome">
                <img :src="mc_logo" alt="Logo" />
              </a>
              <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="nav" class="navbar-nav ms-auto">
                  <li class="nav-item" @click="gohome">
                    <a class="dd-menu active collapsed" data-bs-toggle="collapse" data-bs-target="#submenu-1-1"
                      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">{{
                $t("home") }}</a>
                  </li>

                  <li class="nav-item">
                    <a href="#" aria-label="Toggle navigation" @click="godocument">{{ $t("document") }}</a>
                  </li>
                  <li class="nav-item">
                    <a href="#" aria-label="Toggle navigation" @click="goserve">{{ $t("serve") }}</a>
                  </li>
                  <li class="nav-item">
                    <a class="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                      data-bs-target="#submenu-1-3" aria-controls="navbarSupportedContent" aria-expanded="false"
                      aria-label="Toggle navigation">{{ $t("language") }}</a>
                    <ul class="sub-menu collapse" id="submenu-1-3">
                      <li class="nav-item"><a href="#" @click="switchToChinese">简体中文</a></li>
                      <li class="nav-item"><a href="#" @click="switchToEnglish">English</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- navbar collapse -->
              <div class="button">
                <a href="#" class="signin" @click="gologin">{{ $t("login") }}</a>
                <a href="#" class="signup" @click="goregister">{{ $t("register") }}</a>
              </div>
            </nav>
            <!-- End Navbar -->
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </header>
  <!-- End Header Area -->

  <!-- Start Hero Area -->
  <section class="hero-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12 col-12">
          <div class="hero-content">
            <h1>
              <span class="wow fadeInUp" data-wow-delay=".4s">{{ mc_website_name }}</span>
              <span class="wow fadeInUp" data-wow-delay=".8s">{{ $t("p1") }}</span>
              <span class="color2 wow fadeInUp" data-wow-delay="1.6s">{{ $t("p2") }}</span>
            </h1>
            <p>{{ $t("p3") }}</p>
            <div class="button">
              <a href="javascript:;" class="btn" @click="goserve">{{ $t("p4") }}</a>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-12">
          <div class="app-image">
            <img src="/assets/images/hero/home.jpg" alt="#" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero Area -->

  <!-- Start Services Area -->
  <section class="services section">
    <div class="container">
      <div class="row">
        <div class="col-14">
          <div class="section-title">
            <h2 class="wow fadeInUp" data-wow-delay=".4s">{{ $t("p5") }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".4s">
          <!-- Start Single Service -->
          <div class="single-service one">
            <img src="/assets/images/hero/11.png" alt="" />

            <h3>{{ $t("p6") }}</h3>
            <p>{{ $t("p7") }}</p>
            <a href="#" @click="goregister">{{ $t("p10") }}<i class="lni lni-arrow-right"></i></a>
          </div>
          <!-- End Single Service -->
        </div>
        <div class="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".6s">
          <!-- Start Single Service -->
          <div class="single-service two">
            <img src="/assets/images/hero/22.png" alt="" />

            <h3>{{ $t("p8") }}</h3>
            <p>{{ $t("p9") }}</p>
            <a href="#" @click="goregister">{{ $t("p10") }}<i class="lni lni-arrow-right"></i></a>
          </div>
          <!-- End Single Service -->
        </div>
        <div class="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".8s">
          <!-- Start Single Service -->
          <div class="single-service three">
            <img src="/assets/images/hero/33.png" alt="" />
            <h3>{{ $t("t1") }}</h3>
            <p>{{ $t("t2") }}</p>
            <a href="#" @click="goregister">{{ $t("p10") }} <i class="lni lni-arrow-right"></i></a>
          </div>
          <!-- End Single Service -->
        </div>
      </div>
    </div>
  </section>

  <!-- Start Call Action Area -->
  <section class="call-action">
    <div class="container">
      <div class="inner-content">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-2 col-4">
            <img src="/assets/images/hero/0.png" style="width: 5rem; padding-top: 1rem" />
          </div>
          <div class="col-lg-2 col-md-2 col-4">
            <img src="/assets/images/hero/1.png" style="width: 5rem; padding-top: 1rem" />
          </div>
          <div class="col-lg-2 col-md-2 col-4">
            <img src="/assets/images/hero/2.png" style="width: 5rem; padding-top: 1rem" />
          </div>
          <div class="col-lg-2 col-md-2 col-4" style="padding-top: 1rem">
            <img src="/assets/images/hero/3.png" style="width: 5rem" />
          </div>
          <div class="col-lg-2 col-md-2 col-4" style="padding-top: 1rem">
            <img src="/assets/images/hero/4.png" style="width: 5rem" />
          </div>
          <div class="col-lg-2 col-md-2 col-4" style="padding-top: 1rem">
            <img src="/assets/images/hero/5.png" style="width: 5rem" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Call Action Area -->

  <!-- Start Footer Area -->
  <footer class="footer section">
    <!-- Start Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="inner-content">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <!-- Single Widget -->
              <div class="single-footer f-about">
                <div class="logo">
                  <a href="index.html">
                    <img :src="mc_logo" alt="#" />
                  </a>
                </div>
                <p>
                  {{ $t("F1") }}
                </p>
                <p class="copyright-text">
                  <span>© 2023 All Rights Reserved.</span>
                </p>
              </div>
              <!-- End Single Widget -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Footer Top -->
  </footer>
</template>


<script setup>
  import { ref, onMounted } from "vue";
  import { useStore } from 'vuex';
  import { useRouter } from "vue-router";
  import WOW from "../../public/assets/js/wow.min.js";

  const preloader = ref(null);
  const router = useRouter();
  const store = useStore();
  const webdata = ref();
  const mc_website_name = ref("");
  const mc_logo = ref("");

  const switchToChinese = () => {
    localStorage.setItem("language", "zh-CN");
    window.location.reload();
  };

  const switchToEnglish = () => {
    localStorage.setItem("language", "en-US");
    window.location.reload();
  };

  const gologin = () => {
    window.location.href = webdata.value.mc_singin_link;
  };

  const godocument = () => {
    window.location.href = webdata.value.doc_link;
  };


  const goserve = () => {
    window.location.href = webdata.value.mc_website_contact_telegram;
  };

  const gohome = () => {
    router.push("/");
  };

  const goregister = () => {
    router.push("/register");
  };

  const fadeout = () => {
    if (preloader.value) {
      preloader.value.style.opacity = "0";
      preloader.value.style.display = "none";
    }
  };

  const handleScroll = () => {
    const navbarArea = document.querySelector(".navbar-area");
    if (!navbarArea) return; // 添加条件以确保 navbarArea 不为 null

    const sticky = navbarArea.offsetTop;

    if (window.pageYOffset > sticky) {
      navbarArea.classList.add("sticky");
    } else {
      navbarArea.classList.remove("sticky");
    }

    const backToTop = document.querySelector(".scroll-top");
    if (!backToTop) return; // 添加条件以确保 backToTop 不为 null
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      backToTop.style.display = "flex";
    } else {
      backToTop.style.display = "none";
    }
  };

  onMounted(() => {
    store.dispatch('asyncgetwebdata').then(configdata => {
      console.log("configdata", configdata);
      webdata.value = configdata;
      mc_website_name.value = configdata.mc_website_name;
      mc_logo.value = configdata.mc_logo;
    });
    // if (code) {
    //   localStorage.setItem("referralcode", code);
    // }
    window.setTimeout(fadeout, 500);
    new WOW().init();
    window.addEventListener("scroll", handleScroll);
  })
</script>
