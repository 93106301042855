import { createStore } from 'vuex'
import { getwebdata } from '@/request/api'

export default createStore({
  state: {
    webdata: {}
  },
  getters: {
  },
  mutations: {
    updatewebdata(state, val) {
      state.webdata = val;
    },
  },
  actions: {
    asyncgetwebdata(store) {
      return new Promise((resolve, reject) => {
        const now = new Date().getTime();
        const webdata = JSON.parse(localStorage.getItem('webdata'));
        if (webdata && (now - webdata.timestamp) < 30000) {
          resolve(webdata.data);
          return;
        }
        getwebdata({}).then((res)=>{
          if(res.code === 200) {
            store.commit('updatewebdata', res.data);
            localStorage.setItem('webdata', JSON.stringify({ timestamp: now, data: res.data }));
            resolve(res.data);
          }
        }).catch(error => {
          reject(error)
        })
      });
    },
  },
  modules: {}
})
