import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import zhCN from "@/assets/i18n/zh.json";
import enUS from "@/assets/i18n/en.json";

const locale = localStorage.getItem("language") || "zh-CN";

const i18n = createI18n({
  locale: locale,
  messages: {
    "zh-CN": zhCN,
    "en-US": enUS,
  },
});

createApp(App).use(store).use(i18n).use(router).mount("#app");
